'use client';

import { MONSIDO_TOKEN } from '@/lib/constants';
import Script from 'next/script';

const Monsido = () => {
  return (
    MONSIDO_TOKEN && (
      <Script
        src="https://app-script.monsido.com/v2/monsido-script.js"
        onLoad={() => {
          window._monsido = window._monsido || {
            token: MONSIDO_TOKEN,
            statistics: {
              enabled: true,
              cookieLessTracking: true,
              documentTracking: {
                enabled: true,
                documentCls: 'monsido_download',
                documentIgnoreCls: 'monsido_ignore_download',
                documentExt: [],
              },
            },
          };
        }}
        onError={(error) => {
          console.warn('Monsido script error:', error);
        }}
      />
    )
  );
};

export default Monsido;
